
import $app from '@/plugins/modules';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ISalarySettings } from '../types';

@Component
export default class SalaryDialog extends Vue {
  @Prop({ default: () => false })
  readonly value: boolean | undefined;

  @Prop({ default: () => false })
  readonly processing: boolean | undefined;

  @Prop({ default: () => null })
  readonly salary: ISalarySettings | undefined;

  item: ISalarySettings | null = null;

  @Watch('value')
  onShow() {
    if (this.value) {
      this.item = $app.clone(this.salary);
    }
  }

  get afterFee(): boolean {
    return this.item?.afterFee === 'Y';
  }

  set afterFee(value: boolean) {
    if (this.item) {
      this.item.afterFee = (value ? 'Y' : 'N');
    }
  }

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value);
  }

  recalc() {
    this.$emit('recalc', this.item)
  }

  store() {
    if (this.item) {
      this.item.userName = $app.label('UsersGlossary', this.item.userId!);
    }
    this.$emit('store', this.item)
  }

  remove() {
    this.$emit('remove', this.item)
  }
}
